import React from 'react';
import CartFlyout from '../../Cart/CartFlyout';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import { styled } from 'linaria/react';
import CartButton from '../../Cart/CartButton';

import { theme } from '../../Theme';

import SearchBar from './SearchBar';
import SearchButton from './SearchButton';
import { Link } from 'react-router-dom';

import { ReactComponent as SmyckaLogoCollapsed } from '../../../svg/SmyckaLogo.svg';
import FavouritesMenuButton from '../../Favourites/FavouritesMenuButton';
import StoreFinderButton from '../../Theme/StoreFinderButton';

const Nav = styled('nav')`
  position: static;
  border-bottom: 1px solid #e8e8e8;
  text-transform: uppercase;
  top: -100%;
  transition: 0.4s top;
  background: ${theme.colors.white};
  z-index: 100;

  &[data-scrolled='true'] {
    ${theme.above.md} {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 78px;
      z-index: 999;
    }
  }
`;

const CollapsedLogo = styled('div')`
  position: absolute;
  top: 23px;
  left: 0px;
  width: 122px;
  height: 34px;
  z-index: 100;

  svg {
    width: 122px;
    height: 34px;
  }
`;

const MenuContainerWrapper = styled('div')`
  &[data-scrolled='true'] {
    ${theme.above.md} {
      position: relative;
      height: 100%;
      max-width: 80rem;
      margin: auto;
      z-index: 10;
    }
  }
`;

const FlexSection = styled('div')`
  display: flex;

  > * {
    margin-right: ${theme.space[1]};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledFlexSection = styled(FlexSection)`
  position: absolute;
  top: 27px;
  right: 0px;
  justify-content: flex-end;
  z-index: 100;

  &[data-search-open='true'] {
    width: 40%;
  }

  > * {
    margin-right: 0px;
  }

  button {
    &:first-of-type {
      margin-right: 0;
    }
  }

  > svg {
    width: 22px;
    height: 22px;
    margin-right: ${theme.space[4]};
  }
`;

const Cover = styled('div')`
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 12;
`;

const MainMenu = ({
  isTransparent,
  scrolled,
  categories,
  searchOpen,
  setSearchOpen
}) => {
  // Have to redeclare states here. Cant't be passed down ...
  const [searchIconVisible, setSearchIconVisible] = React.useState(true);
  const [showCover, setShowCover] = React.useState(false);

  const isOpen = isOpen => {
    setShowCover(isOpen);
  };

  React.useEffect(() => {
    if (!scrolled) {
      setSearchIconVisible(true);
      setSearchOpen(false);
    }
  }, [scrolled, setSearchOpen]);


  const focusOnSearch = () => {
    const [searchbar] = document.querySelectorAll('.searchbar'); // Get the first search bar
    if (searchbar) {
      searchbar.focus();
    }
  }

  return (
    <>
      {showCover && <Cover />}
      <Nav data-scrolled={scrolled}>
        <MenuContainerWrapper data-scrolled={scrolled}>
          {scrolled && (
            <CollapsedLogo>
              <Link to="/">
                <SmyckaLogoCollapsed />
              </Link>
            </CollapsedLogo>
          )}

          {scrolled && !searchOpen && (
            <MenuContainer
              scrolled={scrolled}
              queryData={categories}
              isOpen={isOpen}
            />
          )}

          {!scrolled && (
            <MenuContainer queryData={categories} isOpen={isOpen} />
          )}

          {scrolled && (
            <StyledFlexSection data-search-open={searchOpen}>
                <SearchButton
                  searchOpen={searchOpen}
                  onClick={() => focusOnSearch()}
                />
              {!scrolled && <StoreFinderButton showLabel={false} />}
              {!scrolled && <FavouritesMenuButton showLabel={false} />}
              <CartButton showLabel={false} />
            </StyledFlexSection>
          )}

          <CartFlyout />
        </MenuContainerWrapper>
      </Nav>
    </>
  );
};

export default MainMenu;
